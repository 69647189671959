<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>
    <!-- 大模块 -->
    <div class="bigCss">
      <!-- 公司介绍 -->
      <!-- 公司介绍 -->
      <div class="biaoti">
        <img style="margin-right: 16px" src="./img/公司介绍.png" alt="" />
        Company Introduction
      </div>
      <div
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.05);
          margin-top: 4px;
        "
      >
        Company Introduction
      </div>
      <img
        style="width: 1386px; margin-top: 28px; margin-bottom: 60px"
        src="./img/公司图.png"
        alt=""
      />
      <!-- <div class="zhengwen">
        武汉烽火富华电气有限责任公司于2008年10月由烽火科技集团发起成立，注册资本壹亿壹仟捌佰万元，致力于成为智慧能源与智慧城市领域领先的产品与解决方案提供商。
      </div> -->
      <div class="zhengwen">
        Wuhan Fiberhome Fuhua Electric Co., Ltd. was founded in October 2008 by Fiberhome Technology Group with a registered capital of 118 million yuan, committed to becoming a leading product and solution provider in the field of smart energy and smart city. The company is Wuhan East Lake High-tech Development Zone "3551 talent" enterprise, "high-tech enterprise", Wuhan City "thousands of enterprises" enterprise, Wuhan City "silver seed" enterprise, Wuhan City science and technology innovation enterprise center, national specialized special new "little giant" enterprise.
      </div>
      <div class="zhengwen">
        Adhering to the value concept of "equality, mutual trust, cooperation and win-win", the company has brought together a group of high-tech talents in the fields of electronics, computers, electrical automation and new energy, and has established close cooperation relations with universities and research institutes such as Tsinghua University, Huazhong University of Science and Technology, Wuhan University, National Optoelectronics Research Center, and Chinese Academy of Sciences. The developed products have independent intellectual property rights.
      </div>
      <div class="zhengwen">
        The company focuses on two major areas: smart energy and smart city. The business covers energy storage (including centralized energy storage, industrial and commercial energy storage, distributed energy storage, household energy storage, mobile energy storage, etc.), electric vehicle charging pile (DC charging pile and AC charging pile, etc.), power changing station, new energy power generation, intelligent power distribution, smart building, smart security, smart park, smart transportation and other related business product development and overall solution.
        As a national high-tech enterprise, the company always adheres to long-term layout, pursues excellence, enables high-quality development with continuous innovation, actively cultivates new generation information technologies such as artificial intelligence, big data, cloud computing, and blockchain, and becomes a leading product and solution provider in the field of smart energy and smart city.
      </div>

      <!-- 地图模块 -->
      <!-- 地图模块 -->
      <div class="biaoti">
        <img style="margin-right: 16px" src="./img/联系方式.png" alt="" />
        Contact
      </div>
      <div
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.05);
          margin-top: 4px;
        "
      >
        Contact
      </div>
      <div class="ditu">
        <iframe style="border-radius: 8px 8px 8px 8px" width="1314" height="498" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/72aaSDBq11k"></iframe>
        <div class="rightTop">
          <div class="rBox" v-for="(item, index) in this.dituData" :key="index">
            <img :src="item.url" alt="" />
            <div class="titleBOX">
              <div class="tName">{{ item.title }}</div>
              <div class="tData">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
          class="rightBottom"
        >
          <img style="margin-left: 24px" src="./img/码小.png" alt="" />
          <div style="margin-left: 24px" class="tData">Wechat public account</div>
        </div>
        <div v-show="isShow" class="erweima">
          <img style="margin-top: 24px" src="./img/码大.png" alt="" />
          <div class="tName">Wechat public account</div>
        </div>
      </div>

      <!-- 资质荣誉 -->
      <!-- 资质荣誉 -->
      <div class="zzDiv">
        <div class="biaoti">
          <img style="margin-right: 16px" src="./img/资质.png" alt="" />
          Qualification honor
        </div>
        <div
          style="
            font-size: 28px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.05);
            margin-top: 4px;
          "
        >
        Qualification honor
        </div>

        <el-carousel  @change="handleCarouselChange" style="width: 1314px;background-color: rgba(255, 255, 255, 0.80);margin-top: 60px;" :interval="4000" type="card" height="512px">
    <el-carousel-item style="margin-top: 0px;" v-for="item in this.imgData" :key="item">
      <div style="display: flex;
    flex-direction: column;
    align-items: center;padding-top: 60px;">
        <img  ref="imgH" style="width: 230px;height: 345px;" :src="item.url" alt="" >

      </div>
      
    </el-carousel-item>
    <h3 class="imgName" >{{imgname}}</h3>
  </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgname:'质量管理体系认证证书',
      isShow: false,
      dituData: [
        {
          url: require("./img/地址.png"),
          title: "address",
          content: "武汉市东湖新技术开发区邮科院路88号",
        },
        {
          url: require("./img/邮箱.png"),
          title: " E-mail",
          content: "AD@ffepower.com",
        },
        {
          url: require("./img/电话.png"),
          title: "Marketing Department",
          content: "+86-27-87693118",
        },
        {
          url: require("./img/电话.png"),
          title: "General management department",
          content: "+86-27-87693119",
        },
      ],
      imgData:[
        {
          url: require("./img/证书1.png"),
        },
        {
          url: require("./img/证书2.png"),
        },
        {
          url: require("./img/证书3.png"),
        },
        {
          url: require("./img/证书4.png"),
        },
        {
          url: require("./img/证书5.png"),
        },
        {
          url: require("./img/证书6.png"),
        },
      ]
    };
  },
  methods: {
    handleCarouselChange(index) {
    if (index=='0') {
      this.imgname='质量管理体系认证证书'
    }else if (index=='1') {
      this.imgname='知识产权管理体系认证证书'
    }else if (index=='2') {
      this.imgname='企业3A证书'
    }
    else if (index=='3') {
      this.imgname='军运会感谢信'
    }
    else if (index=='4') {
      this.imgname='江夏疫情指挥部感谢信'
    }
    else if (index=='5') {
      this.imgname='荣誉证书'
    }else{
      this.imgname='荣誉证书'
    }
    
    
  },
    mouseOver() {
      this.isShow = true;
    },
    // 鼠标移出
    mouseLeave(val) {
      this.timeoutId = setTimeout(() => {
        this.isShow = false;
      }, 500); // 延迟500毫秒后隐藏导航栏
      // this.leftBox=[]
    },
  },
  mounted(){
    this.handleCarouselChange()
  }
};
</script>

<style scoped>
.bigCss {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #ffffff;
}
.biaoti {
  display: flex;
  align-items: center;
  margin-top: 100px;
  font-size: 30px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.zhengwen {
  text-indent: 2em;
  width: 1314px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #63798d;
  line-height: 42px;
}
.ditu {
  margin-top: 46px;
  position: relative;
}
.rightTop {
  width: 430px;
  height: 350px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
}
.rightBottom {
  width: 430px;
  height: 84px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 2;

  display: flex;
  align-items: center;
}

.rBox {
  margin-top: 32px;
  margin-left: 24px;
  display: flex;
  align-items: center;
}
.titleBOX {
  margin-left: 24px;
}
.tName {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
.tData {
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}
.erweima {
  display: flex;
  align-items: center;
  flex-direction: column;

  position: absolute;
  bottom: 24px;
  right: -254px;
  width: 230px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 12px 12px;
}
.zzDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1920px;
  height: 920px;
  background-image: url("./img/资质荣誉.png");
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .imgName{
  position: absolute;
  top: 449px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 22px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: rgba(0, 0, 0, 0.9);
}
</style>